
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import AbpBase from "../../../lib/abpbase";
import Campana from "../../../store/entities/OZONE/campana";
import DisenoExportacion from "../../../store/entities/OZONE/disenoExportacion";
import SelectCampana from "../campana/selectCampana.vue";
import GenerarExportacion from "../../../store/entities/OZONE/generarExportacion";
import { Moment } from "moment";
import BaseRangePicker from "../../../components/Inputs/BaseRangePicker.vue";
import BaseInput from "../../../components/Inputs/BaseInput.vue";
import BaseSwitch from "../../../components/Inputs/BaseSwitch.vue";
import BaseFiltrableDropdown from "../../../components/Inputs/BaseFiltrableDropdown.vue";

@Component({
  components: {
    SelectCampana, 
    BaseRangePicker,
    BaseInput,
    BaseSwitch,
    BaseFiltrableDropdown
  },
})
export default class ExportacionEnvios extends AbpBase {
  labelCol = { span: 4 };
  wrapperCol = { span: 14 };

  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };

  id = 0;

  form: any;

  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "dynamic_form_item" });
    this.form.getFieldDecorator("keys", { initialValue: [], preserve: true });
  }

  remove(k) {
    const { form } = this;
    // can use data-binding to get
    const keys = form.getFieldValue("keys");
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.setFieldsValue({
      keys: keys.filter((key) => key !== k),
    });
  }

  add() {
    const { form } = this;
    // can use data-binding to get
    const keys = form.getFieldValue("keys");
    const nextKeys = keys.concat(this.id++);
    // can use data-binding to set
    // important! notify form to detect changes
    form.setFieldsValue({
      keys: nextKeys,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.form.validateFields((err, values) => {
      if (!err) {
        const { keys, names } = values;
        console.log("Received values of form: ", values);
        console.log(
          "Merged values:",
          keys.map((key) => names[key])
        );
      }
    });
  };

  activeKey = 1;
  formEnvios: {
    campana: Campana;
    design: DisenoExportacion;
    date1: [Moment, Moment];
    custom: false;
    type: Array<number>;
    mailing: "";
    file: "";
    desc: "";
  } = {
    campana: null,
    design: undefined,
    date1: [undefined, undefined],
    custom: false,
    type: [],
    mailing: "",
    file: "",
    desc: "",
  };

  formParticipaciones: {
    campana: Campana;
    design: DisenoExportacion;
    date1: [Moment, Moment];
    custom: false;
    type: Array<number>;
    file: "";
    desc: "";
  } = {
    campana: null,
    design: undefined,
    date1: [undefined, undefined],
    custom: false,
    type: [],
    file: "",
    desc: "",
  };

  exportRules = {
    campana: {
      required: { filtrableDropdownRequired: true }
    },
    mailing:{
      required: { required: true }
    },
    fechas: {
      required: { rangePickerRequired: true }
    },
    fileName: {
      required: { required: true }
    },
    outputDesign: {
      required: { filtrableDropdownRequired: true }
    },
    file: [
      {
        required: true,
        message: "El nombre es obligatorio",
        trigger: "blur",
      },
    ],
    design: [
      {
        required: true,
        message: "El formato es obligatorio",
        trigger: "blur",
      },
    ],
  };

  get loading() {
    return this.$store.state.envio.loading || this.$store.state.participacion.loading;
  }

  get disenosEnvios(){
    let disenosFiltrados = []
    this.$store.state.envio.disenos.map(item => {
      if(item.disenoTipo === 0){
        disenosFiltrados.push(item);
      }
    });
    return disenosFiltrados;
  }

  get disenosParticipaciones(){
    let disenosFiltrados = []
    this.$store.state.envio.disenos.map(item => {
      if(item.disenoTipo === 1){
        disenosFiltrados.push(item);
      }
    });
    return disenosFiltrados;
  }

  async created() {
    this.formEnvios.campana = null;
    this.formParticipaciones.campana = null;
    await this.$store.dispatch({
      type: "envio/getDisenos",
      data: { withContent: false },
    });
    await this.$store.dispatch({
      type: "estadoParticipacion/getAll",
      data: { maxResultCount: 50, skipCount: 0 },
    });
  }

  async onSubmitEnvios() {
    var generarExportacion = new GenerarExportacion();

    generarExportacion.campana = this.formEnvios.campana;
    generarExportacion.from = this.formEnvios.date1[0]
      ? this.formEnvios.date1[0].format('YYYY-MM-DD')
      : null;
    generarExportacion.to = this.formEnvios.date1[1]
      ? this.formEnvios.date1[1].format('YYYY-MM-DD')
      : null;

    generarExportacion.mailing = this.formEnvios.mailing;
    generarExportacion.estadoId = this.formEnvios.type
      ? this.formEnvios.type
      : null;

    generarExportacion.observaciones = this.formEnvios.desc;
    generarExportacion.disenoExportacion = this.formEnvios.design.id;
    generarExportacion.fileName = this.formEnvios.file;

    await this.$store
      .dispatch({
        type: "envio/generarExportacion",
        data: generarExportacion,
      })
      .then((content) => {
        var fileName = this.formEnvios.file + ".csv";

        let blob = this.dataURLtoFile(
          "data:text/csv;base64," + content,
          fileName
        );
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      });
  }

  async onSubmitParticipaciones() {
    var generarExportacion = new GenerarExportacion();
    generarExportacion.campana = this.formParticipaciones.campana;
    generarExportacion.from = this.formParticipaciones.date1[0]
      ? this.formParticipaciones.date1[0].format('YYYY-MM-DD')
      : null;
    generarExportacion.to = this.formParticipaciones.date1[1]
      ? this.formParticipaciones.date1[1].format('YYYY-MM-DD')
      : null;

    generarExportacion.estadoId = this.formParticipaciones.type
      ? this.formParticipaciones.type
      : null;

    generarExportacion.observaciones = this.formParticipaciones.desc;
    generarExportacion.disenoExportacion = this.formParticipaciones.design.id;
    generarExportacion.fileName = this.formParticipaciones.file;

    await this.$store
      .dispatch({
        type: "participacion/generarExportacion",
        data: generarExportacion,
      })
      .then((content) => {
        var fileName = this.formParticipaciones.file + ".csv";

        let blob = this.dataURLtoFile(
          "data:text/csv;base64," + content,
          fileName
        );
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      });
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  get estadoList() {
    return this.$store.state.estadoParticipacion.list;
  }
}
